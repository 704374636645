import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './store'

/*DSGOV CSS*/
import '@govbr/dsgov/dist/dsgov.min.css';
// import "https://h-barragovbr.np.estaleiro.serpro.gov.br/v1/barra-govbr-wc.js";
import "https://h-barragovbr.np.estaleiro.serpro.gov.br/v1/barra-govbr-wc.js"; // producao

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
